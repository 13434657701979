import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'; // Import eye icons

const LoginAdmin = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
  const navigate = useNavigate();

  const handleLogin = (e) => {
    e.preventDefault();

    // Check credentials
    if (username === 'admin123' && password === '123rahasianegara#') {
      navigate('/dashboard-admin'); // Redirect to Admin page
    } else {
      alert('Invalid credentials!'); // Simple error handling
    }
  };

  return (
    <div className="min-h-screen bg-emerald-700 flex flex-col">
      <main className="flex-grow flex items-center justify-center">
        <div className="bg-white p-8 rounded-lg shadow-lg w-96">
          <h2 className="text-2xl font-bold mb-6 text-center">Login Admin</h2>
          <form onSubmit={handleLogin}>
            <div className="mb-4">
              <label className="block text-gray-700">Username</label>
              <input
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded"
                required
              />
            </div>
            <div className="mb-4 relative"> {/* Relative positioning for the eye icon */}
              <label className="block text-gray-700">Password</label>
              <input
                type={showPassword ? 'text' : 'password'} // Toggle password visibility
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded"
                required
              />
              <span 
                onClick={() => setShowPassword(!showPassword)} // Toggle function
                className="absolute right-3 top-10 cursor-pointer"
              >
                {showPassword ? <AiFillEyeInvisible /> : <AiFillEye />} {/* Show/Hide icon */}
              </span>
            </div>
            <button type="submit" className="w-full bg-emerald-600 text-white p-2 rounded">
              Login
            </button>
          </form>
        </div>
      </main>
    </div>
  );
};

export default LoginAdmin;

// Widget.js
import React from 'react';

const Widget = ({ title, subtitle, icon, className }) => {
  return (
    <div className={`p-4 bg-white rounded-lg shadow-md ${className}`}>
      <div className="flex items-center">
        <div className="mr-3">{icon}</div>
        <div>
          <h3 className="text-lg font-semibold">{title}</h3>
          <p className={`text-sm ${className}`}>{subtitle}</p>
        </div>
      </div>
    </div>
  );
};

export default Widget;

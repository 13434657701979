import React from 'react';
import { Disclosure } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom

const Navbar = () => {
  const navigate = useNavigate(); // Initialize useNavigate

  const navigation = [
    { name: 'Home', href: '#hero', current: false },
    { name: 'Archive', href: '#services', current: false }
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  const handleLoginAdmin = () => {
    navigate('/admin'); // Navigate to the Admin page
  };

  return (
    <header className="fixed inset-x-0 top-0 z-50">
      <Disclosure as="nav" className="bg-emerald-700 rounded-b-xl">
        <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
          <div className="relative flex h-20 items-center justify-between">
            <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
              {/* Mobile menu button */}
              <Disclosure.Button className="group relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-yellow-400 hover:text-black focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                <span className="absolute -inset-0.5" />
                <span className="sr-only">Open main menu</span>
                <Bars3Icon aria-hidden="true" className="block h-6 w-6 group-data-[open]:hidden" />
                <XMarkIcon aria-hidden="true" className="hidden h-6 w-6 group-data-[open]:block" />
              </Disclosure.Button>
            </div>
            <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
              <div className="flex flex-shrink-0 items-center">
                <img
                  alt="Your Company"
                  src="https://i.ibb.co/VwYSMmC/logo.png"
                  className="h-8 w-auto"
                />
              </div>
            </div>
            <div className="hidden sm:ml-6 sm:block">
              <div className="flex space-x-4">
                {navigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    aria-current={item.current ? 'page' : undefined}
                    className={classNames(
                      item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-yellow-400 hover:text-black',
                      'rounded-md px-3 py-2 text-sm font-medium',
                    )}
                  >
                    {item.name}
                  </a>
                ))}
                {/* Add Login Admin Button */}
                <button
                  onClick={handleLoginAdmin}
                  className="rounded-md bg-yellow-400 px-3 py-2 text-sm font-medium text-black hover:bg-yellow-500"
                >
                  Login Admin
                </button>
              </div>
            </div>
          </div>
        </div>

        <Disclosure.Panel className="sm:hidden">
          <div className="space-y-1 px-2 pb-3 pt-2">
            {navigation.map((item) => (
              <Disclosure.Button
                key={item.name}
                as="a"
                href={item.href}
                aria-current={item.current ? 'page' : undefined}
                className={classNames(
                  item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-yellow-400 hover:text-black',
                  'block rounded-md px-3 py-2 text-base font-medium',
                )}
              >
                {item.name}
              </Disclosure.Button>
            ))}
            {/* Add Login Admin Button in Mobile View */}
            <Disclosure.Button
              onClick={handleLoginAdmin}
              className="block rounded-md px-3 py-2 text-base font-medium text-black hover:bg-yellow-500"
            >
              Login Admin
            </Disclosure.Button>
          </div>
        </Disclosure.Panel>
      </Disclosure>
    </header>
  );
};

export default Navbar;

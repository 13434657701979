import React from 'react';
import Card from '../components/card'; // Adjust the import path as needed
import BarChart from '../components/charts/BarChart'; // Adjust the import path as needed
import { MdBarChart } from 'react-icons/md';
import { useWeeklyRevenueData } from './useWeeklyRevenueData';

const WeeklyRevenue = () => {
  const { weeklyRevenueData, loading, error } = useWeeklyRevenueData();

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  const barChartOptionsWeeklyRevenue = {
    responsive: true,
    plugins: {
      tooltip: {
        callbacks: {
          label: (tooltipItem) => `$${tooltipItem.raw}`,
        },
      },
    },
    scales: {
      y: {
        title: {
          display: true,
          text: 'Total Amount',
        },
        max: 100000, // Set the maximum Y-axis value to 1,000,000
        ticks: {
          stepSize: 10000, // Set the gap between ticks to 100,000
        },
      },
    },
  };


  return (
    <Card extra="flex flex-col bg-white text-black w-full rounded-3xl shadow-lg py-6 px-4 text-center">
      <div className="flex items-center justify-between px-6 mb-4">
        <h2 className="text-xl font-bold text-navy-700 dark:text-black">
          Pemasukan Mingguan
        </h2>
        <button className="flex items-center justify-center rounded-lg bg-lightPrimary p-2 text-brand-500 transition duration-200 hover:bg-gray-200 active:bg-gray-300 dark:bg-navy-700 dark:text-black dark:hover:bg-white/20 dark:active:bg-white/10">
          <MdBarChart className="h-6 w-6" />
        </button>
      </div>

      <div className="flex justify-center md:mt-4 lg:mt-0">
        {/* Adjust the height and width for the chart */}
        <div className="h-auto w-full">
          <BarChart
            chartData={weeklyRevenueData}
            chartOptions={barChartOptionsWeeklyRevenue}
          />
        </div>
      </div>
    </Card>
  );
};

export default WeeklyRevenue;

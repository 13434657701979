import React, { useEffect, useState } from 'react';
import { getFirestore, collection, getDocs } from "firebase/firestore"; 
import { initializeApp } from "firebase/app";

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBr4y3JRw8Wq35A5nfxBPxyOAOtRneSNPg",
  authDomain: "tabunganbersama-58f2e.firebaseapp.com",
  projectId: "tabunganbersama-58f2e",
  storageBucket: "tabunganbersama-58f2e.appspot.com",
  messagingSenderId: "335269822572",
  appId: "1:335269822572:web:18bc0a52a32f3d3cf85849",
  measurementId: "G-VH2GS1PLQ6"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const FinancialAnalysis = ({ setFinancialAnalysisTotal }) => { // Receive setter as prop
  const [financialData, setFinancialData] = useState([]);
  const [analysis, setAnalysis] = useState({});

  useEffect(() => {
    const fetchFinancialData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "tabunganbersama"));
        const data = querySnapshot.docs.map(doc => doc.data());
        setFinancialData(data);
        analyzeData(data);
      } catch (error) {
        console.error("Error fetching financial data: ", error);
      }
    };

    fetchFinancialData();
  }, []);

  const analyzeData = (data) => {
    const totals = data.reduce((acc, curr) => {
      acc[curr.name] = (acc[curr.name] || 0) + curr.amount;
      return acc;
    }, {});

    setAnalysis(totals);

    // Calculate total amount and pass it to TotalSpent
    const totalAmount = Object.values(totals).reduce((sum, amount) => sum + amount, 0);
    setFinancialAnalysisTotal(totalAmount);
  };

  return (
    <div className="bg-white text-black p-6 rounded-lg shadow-lg">
      <h2 className="text-2xl font-bold text-black mb-4">Financial Analysis</h2>
      <table className="min-w-full bg-white border border-gray-300">
        <thead>
          <tr>
            <th className="border-b p-2">Name</th>
            <th className="border-b p-2">Total Amount</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(analysis).map(([name, total]) => (
            <tr key={name}>
              <td className="border-b py-2">{name}</td>
              <td className="border-b py-2">Rp.{total.toFixed()}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default FinancialAnalysis;

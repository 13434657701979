// src/hooks/useWeeklyRevenueData.js
import { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../components/Database"; // Adjust this import according to your project structure

export const useWeeklyRevenueData = () => {
  const [weeklyRevenueData, setWeeklyRevenueData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "tabunganbersama")); // Ensure this is the correct collection name
        const data = querySnapshot.docs.map(doc => ({
          id: doc.id,
          amount: doc.data().amount || 0, // Adjust based on your document structure
          timestamp: doc.data().timestamp?.toDate() || new Date(), // Ensure 'timestamp' field exists
        }));

        // Calculate total amount per week
        const revenueByWeek = {};
        data.forEach(item => {
          const week = getWeekNumber(item.timestamp); // Get week number
          const amount = item.amount;

          if (!revenueByWeek[week]) {
            revenueByWeek[week] = 0;
          }
          revenueByWeek[week] += amount;
        });

        // Convert object to array for the chart
        const chartData = Object.keys(revenueByWeek).map(week => ({
          week,
          amount: revenueByWeek[week],
        }));

        setWeeklyRevenueData(chartData);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return { weeklyRevenueData, loading, error };
};

// Helper function to get week number from a date
const getWeekNumber = (date) => {
  const start = new Date(date.getFullYear(), 0, 1);
  const days = Math.floor((date - start) / (24 * 60 * 60 * 1000));
  return Math.ceil((days + start.getDay() + 1) / 7);
};

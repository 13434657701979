import React from 'react';
import ReactDOM from 'react-dom/client';
import '../src/assets/css/index.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Welcome from "./components/Welcome";
import LoginAdmin from './components/admin/LoginAdmin';
import Admin from './components/admin/Admin'; // This is the dashboard page

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBr4y3JRw8Wq35A5nfxBPxyOAOtRneSNPg",
  authDomain: "tabunganbersama-58f2e.firebaseapp.com",
  projectId: "tabunganbersama-58f2e",
  storageBucket: "tabunganbersama-58f2e.appspot.com",
  messagingSenderId: "335269822572",
  appId: "1:335269822572:web:18bc0a52a32f3d3cf85849",
  measurementId: "G-VH2GS1PLQ6"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Welcome />} />
        <Route exact path="/admin" element={<LoginAdmin />} />
        <Route exact path="/dashboard-admin" element={<Admin />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

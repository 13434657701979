import React, { useState, useEffect } from 'react';
import { getFirestore, collection, addDoc, getDocs, deleteDoc, doc, updateDoc } from "firebase/firestore";
import { initializeApp } from "firebase/app";
import { useNavigate } from 'react-router-dom';

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBr4y3JRw8Wq35A5nfxBPxyOAOtRneSNPg",
  authDomain: "tabunganbersama-58f2e.firebaseapp.com",
  projectId: "tabunganbersama-58f2e",
  storageBucket: "tabunganbersama-58f2e.appspot.com",
  messagingSenderId: "335269822572",
  appId: "1:335269822572:web:18bc0a52a32f3d3cf85849",
  measurementId: "G-VH2GS1PLQ6"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

function Admin() {
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [amount, setAmount] = useState('');
  const [paymentDate, setPaymentDate] = useState(''); // Added state for payment date
  const [transactionId, setTransactionId] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [fetchedNames, setFetchedNames] = useState([]);
  const [newMemberName, setNewMemberName] = useState('');
  const [newMemberWA, setNewMemberWA] = useState('');
  const [memberId, setMemberId] = useState(null);
  const [members, setMembers] = useState([]);

  useEffect(() => {
    const fetchNamesAndTransactions = async () => {
      try {
        const namesSnapshot = await getDocs(collection(db, "members"));
        const namesList = namesSnapshot.docs.map(doc => doc.data().name);
        setFetchedNames(namesList);

        const transactionsSnapshot = await getDocs(collection(db, "tabunganbersama"));
        const transactionsList = transactionsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setTransactions(transactionsList);

        const membersSnapshot = await getDocs(collection(db, "members"));
        const membersList = membersSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setMembers(membersList);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchNamesAndTransactions();
  }, []);

  const sendWhatsAppMessage = (memberName, memberWA) => {
    const message = `Kepada YTH. ${memberName} ditempat, bayar uang bulanan yu :)`;
    const url = `https://wa.me/${memberWA}?text=${encodeURIComponent(message)}`;
    window.open(url, '_blank'); // Open in a new tab
  };

  const handleSubmitFinance = async (e) => {
    e.preventDefault();

    try {
      if (transactionId) {
        const transactionRef = doc(db, "tabunganbersama", transactionId);
        await updateDoc(transactionRef, {
          name: name,
          amount: parseFloat(amount),
          timestamp: new Date(),
          paymentDate: new Date(paymentDate) // Save payment date
        });
        alert("Financial data updated successfully!");
      } else {
        await addDoc(collection(db, "tabunganbersama"), {
          name: name,
          amount: parseFloat(amount),
          timestamp: new Date(),
          paymentDate: new Date(paymentDate) // Save payment date
        });
        alert("Financial data added successfully!");
      }

      setName('');
      setAmount('');
      setPaymentDate(''); // Reset the date field
      setTransactionId(null);
      fetchTransactions();
    } catch (error) {
      console.error("Error adding/updating financial data: ", error);
    }
  };

  const fetchTransactions = async () => {
    const transactionsSnapshot = await getDocs(collection(db, "tabunganbersama"));
    const transactionsList = transactionsSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    setTransactions(transactionsList);
  };

  const handleDeleteTransaction = async (id) => {
    try {
      await deleteDoc(doc(db, "tabunganbersama", id));
      alert("Transaction deleted successfully!");
      fetchTransactions();
    } catch (error) {
      console.error("Error deleting transaction: ", error);
    }
  };

  const handleEditTransaction = (transaction) => {
    setName(transaction.name);
    setAmount(transaction.amount);
    
    // Periksa apakah transaction.paymentDate adalah timestamp Firestore
    if (transaction.paymentDate && transaction.paymentDate.seconds) {
      setPaymentDate(new Date(transaction.paymentDate.seconds * 1000).toISOString().split('T')[0]); // Format tanggal
    } else {
      setPaymentDate(''); // Kosongkan jika tidak ada tanggal
    }

    setTransactionId(transaction.id);
  };

  const handleSubmitMember = async (e) => {
    e.preventDefault();

    try {
      if (memberId) {
        const memberRef = doc(db, "members", memberId);
        await updateDoc(memberRef, {
          name: newMemberName,
          whatsapp: newMemberWA,
        });
        alert("Member updated successfully!");
      } else {
        await addDoc(collection(db, "members"), {
          name: newMemberName,
          whatsapp: newMemberWA,
          joinedAt: new Date(),
        });
        alert("New member added successfully!");
      }

      setNewMemberName('');
      setNewMemberWA('');
      setMemberId(null);
      fetchMembers();
    } catch (error) {
      console.error("Error adding/updating member: ", error);
    }
  };

  const fetchMembers = async () => {
    const membersSnapshot = await getDocs(collection(db, "members"));
    const membersList = membersSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    setMembers(membersList);
  };

  const handleDeleteMember = async (id) => {
    try {
      await deleteDoc(doc(db, "members", id));
      alert("Member deleted successfully!");
      fetchMembers();
    } catch (error) {
      console.error("Error deleting member: ", error);
    }
  };

  const handleEditMember = (member) => {
    setNewMemberName(member.name);
    setNewMemberWA(member.whatsapp);
    setMemberId(member.id);
  };

  const handleLogout = () => {
    navigate('/');
  };

  return (
    <div className="flex flex-col lg:flex-row items-center justify-center min-h-screen bg-gray-100 space-y-8 lg:space-y-0 lg:space-x-8 p-4">
      <button
        onClick={handleLogout}
        className="flex bg-red-500 text-white py-2 px-4 rounded-lg hover:bg-red-600 focus:outline-none"
      >
        Logout
      </button>

      {/* Financial Data Form */}
      <div className="w-full lg:w-1/2 bg-white p-8 rounded-lg shadow-lg">
        <h1 className="text-2xl font-bold text-center mb-6">Tambah Data Keuangan</h1>
        <form onSubmit={handleSubmitFinance} className="space-y-4">
          <div>
            <label className="block text-gray-700">Nama Penabung:</label>
            <select
              className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-indigo-500"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            >
              <option value="">Pilih Nama Penabung</option>
              {fetchedNames.map((financeName, index) => (
                <option key={index} value={financeName}>
                  {financeName}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label className="block text-gray-700">Jumlah:</label>
            <input
              type="number"
              className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-indigo-500"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              required
            />
          </div>
          <div>
            <label className="block text-gray-700">Tanggal Pembayaran:</label>
            <input
              type="date"
              className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-indigo-500"
              value={paymentDate}
              onChange={(e) => setPaymentDate(e.target.value)}
              required
            />
          </div>
          <button
            type="submit"
            className="w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-600 focus:outline-none"
          >
            {transactionId ? 'Update Data' : 'Tambah Data'}
          </button>
        </form>

        {/* Display existing transactions */}
        <h2 className="text-xl font-bold mt-6">Daftar Transaksi</h2>
        <ul className="mt-4">
          {transactions.map((transaction) => (
            <li key={transaction.id} className="flex justify-between items-center py-2 border-b">
              <div>
                <p>{transaction.name}</p>
                <p>Jumlah: {transaction.amount}</p>
                <p>Tanggal Pembayaran: {new Date(transaction.paymentDate.seconds * 1000).toLocaleDateString()}</p>
              </div>
              <div className="space-x-2">
                <button
                  onClick={() => handleEditTransaction(transaction)}
                  className="text-yellow-500 hover:underline"
                >
                  Edit
                </button>
                <button
                  onClick={() => handleDeleteTransaction(transaction.id)}
                  className="text-red-500 hover:underline"
                >
                  Delete
                </button>
              </div>
            </li>
          ))}
        </ul>
      </div>

      {/* Member Management Form */}
      <div className="w-full lg:w-1/2 bg-white p-8 rounded-lg shadow-lg">
        <h1 className="text-2xl font-bold text-center mb-6">Tambah Anggota Baru</h1>
        <form onSubmit={handleSubmitMember} className="space-y-4">
          <div>
            <label className="block text-gray-700">Nama Anggota:</label>
            <input
              type="text"
              className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-indigo-500"
              value={newMemberName}
              onChange={(e) => setNewMemberName(e.target.value)}
              required
            />
          </div>
          <div>
            <label className="block text-gray-700">Nomor WhatsApp:</label>
            <input
              type="text"
              className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-indigo-500"
              value={newMemberWA}
              onChange={(e) => setNewMemberWA(e.target.value)}
              required
            />
          </div>
          <button
            type="submit"
            className="w-full bg-green-500 text-white py-2 rounded-lg hover:bg-green-600 focus:outline-none"
          >
            {memberId ? 'Update Anggota' : 'Tambah Anggota'}
          </button>
        </form>

        {/* Display existing members */}
        <h2 className="text-xl font-bold mt-6">Daftar Anggota</h2>
        <ul className="mt-4">
          {members.map((member) => (
            <li key={member.id} className="flex justify-between items-center py-2 border-b">
              <div>
                <p>{member.name}</p>
                <p>WhatsApp: {member.whatsapp}</p>
              </div>
              <div className="space-x-2">
                <button
                  onClick={() => handleEditMember(member)}
                  className="text-yellow-500 hover:underline"
                >
                  Edit
                </button>
                <button
                  onClick={() => handleDeleteMember(member.id)}
                  className="text-red-500 hover:underline"
                >
                  Delete
                </button>
                <button
                  onClick={() => sendWhatsAppMessage(member.name, member.whatsapp)}
                  className="text-green-500 hover:underline"
                >
                  WhatsApp
                </button>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default Admin;

import React, { useEffect, useState } from "react";
import {
  MdArrowDropUp,
  MdOutlineCalendarToday,
  MdBarChart,
} from "react-icons/md";
import Card from "../components/card";
import { db } from './Database'; // Ensure this imports the correct Firestore setup
import { collection, query, where, getDocs, Timestamp } from 'firebase/firestore'; 
import FinancialAnalysis from "./FinancialAnalysis"; 

const TotalSpent = () => {
  const [totalAmount, setTotalAmount] = useState(0); 
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [financialAnalysisTotal, setFinancialAnalysisTotal] = useState(0);

  useEffect(() => {
    const fetchTotalIncome = async () => {
      try {
        const startOfMonth = new Date();
        startOfMonth.setDate(1); // Get the first day of the current month

        const endOfMonth = new Date(startOfMonth);
        endOfMonth.setMonth(startOfMonth.getMonth() + 1);
        endOfMonth.setDate(0); // Set to the last day of the current month

        const q = query(
          collection(db, 'transactions'),
          where('timestamp', '>=', Timestamp.fromDate(startOfMonth)),
          where('timestamp', '<=', Timestamp.fromDate(endOfMonth))
        );

        const querySnapshot = await getDocs(q);
        const total = querySnapshot.docs.reduce((sum, doc) => {
          const amount = doc.data().amount || 0; 
          return sum + amount;
        }, 0);
        
        setTotalAmount(total);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchTotalIncome();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <Card extra="p-8 text-center">
      <div className="flex justify-between">
        <button className="linear mt-1 flex items-center justify-center gap-2 rounded-lg bg-lightPrimary p-2 text-gray-600 transition duration-200 hover:cursor-pointer hover:bg-gray-100 active:bg-gray-200 dark:bg-navy-700 dark:hover:opacity-90 dark:active:opacity-80">
          <MdOutlineCalendarToday />
          <span className="text-sm font-medium text-gray-600">Total Pemasukan</span>
        </button>
        <button className="!linear z-[1] flex items-center justify-center rounded-lg bg-lightPrimary p-2 text-brand-500 !transition !duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-navy-700 dark:text-black dark:hover:bg-white/20 dark:active:bg-white/10">
          <MdBarChart className="h-6 w-6" />
        </button>
      </div>

      <div className="flex flex-row justify-between sm:flex-wrap lg:flex-nowrap 2xl:overflow-hidden">
        <div className="flex flex-col">
          <p className="mt-[20px] text-3xl font-bold text-navy-700 dark:text-black">
            Rp.{totalAmount + financialAnalysisTotal} {/* Combine both totals */}
          </p>
        </div>
      </div>

      <div className="mt-6">
        <FinancialAnalysis setFinancialAnalysisTotal={setFinancialAnalysisTotal} /> {/* Pass down the setter */}
      </div>
    </Card>
  );
};

export default TotalSpent;

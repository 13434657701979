// Database.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyBr4y3JRw8Wq35A5nfxBPxyOAOtRneSNPg",
    authDomain: "tabunganbersama-58f2e.firebaseapp.com",
    projectId: "tabunganbersama-58f2e",
    storageBucket: "tabunganbersama-58f2e.appspot.com",
    messagingSenderId: "335269822572",
    appId: "1:335269822572:web:18bc0a52a32f3d3cf85849",
    measurementId: "G-VH2GS1PLQ6"
  };
// Check if Firebase is already initialized
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };

import React from 'react';

const Footer = () => {

    return (
            <div
                id='contact'
                className="bg-emerald-700 mt-10 text-center text-white dark:7 lg:text-left">
                <div
                    className="flex items-center justify-center bg-emerald-600 border-b-2 border-neutral-200 p-6 dark:border-neutral-500 lg:justify-between">
                    <div className="mr-12 hidden lg:block">
                        <span>Created with passion and creativity</span>
                    </div>
                    {/* <!-- Social network icons container --> */}
                    <div className="flex justify-center">
                        <a
                            className="mr-6 text-pink-400"
                            href="https://www.instagram.com/arsipan.gapenting_"
                            target="_blank"
                            rel="noopener noreferrer">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-4 w-4"
                                fill="currentColor"
                                viewBox="0 0 24 24">
                                <path
                                    d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
                            </svg>
                        </a>
                    </div>
                </div>

                {/* <!-- Main container */}
                <div className=" bg-emerald-600 py-10 text-center md:text-left">
                    <div className="grid-2 grid gap-8 md:grid-cols-2 lg:grid-cols-3">
                        {/* <!-- Portofolio --> */}
                        <div className="flex flex-col items-center justify-center">
                            <h6 className="mb-4 flex items-center justify-center font-semibold uppercase md:justify-start">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                    className="mr-3 h-4 w-4"
                                >
                                    <path d="M12.378 1.602a.75.75 0 00-.756 0L3 6.632l9 5.25 9-5.25-8.622-5.03zM21.75 7.93l-9 5.25v9l8.628-5.032a.75.75 0 00.372-.648V7.93zM11.25 22.18v-9l-9-5.25v8.57a.75.75 0 00.372.648l8.628 5.033z" />
                                </svg>
                                Arsipan.gapenting_
                            </h6>
                            <p className="text-center">Patch 1.0.1 (Fix bug and Add Monthly Report)
                            </p>
                            <a
                                className="mt-5 text-white flex justify-center"
                                href="https://github.com/Arsipan.gapenting_"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-10 w-10"
                                    fill="currentColor"
                                    viewBox="0 0 24 24"
                                >
                                    <path d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z" />
                                </svg>
                            </a>
                        </div>

                        {/* <!-- Language section --> */}
                        <div className="flex flex-col items-center justify-center">
                            <h6
                                className="mb-4 flex justify-center font-semibold uppercase md:justify-start">
                                Powered by
                            </h6>
                            <p className="mb-4">
                                <p className="text-blue-200"
                                >ReactJS</p>
                            </p>
                            <p className="mb-4">
                                <p className="text-yellow-200"
                                >ExpressJS</p>
                            </p>
                            <p className="mb-4">
                                <p className="text-blue-300"
                                >TailwindCSS</p>
                            </p>
                            <p>
                                <p className="text-yellow-300"
                                >Vite</p>
                            </p>
                        </div>

                        {/* <!-- Contact section --> */}
                        <div className='flex flex-col items-center justify-center'>
                            <h6
                                className="mb-4 flex justify-center font-semibold uppercase md:justify-start">
                                Find Us
                            </h6>
                            <p className="mb-4 flex items-center justify-center md:justify-start">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                    className="mr-3 h-5 w-5">
                                    <path
                                        d="M11.47 3.84a.75.75 0 011.06 0l8.69 8.69a.75.75 0 101.06-1.06l-8.689-8.69a2.25 2.25 0 00-3.182 0l-8.69 8.69a.75.75 0 001.061 1.06l8.69-8.69z" />
                                    <path
                                        d="M12 5.432l8.159 8.159c.03.03.06.058.091.086v6.198c0 1.035-.84 1.875-1.875 1.875H15a.75.75 0 01-.75-.75v-4.5a.75.75 0 00-.75-.75h-3a.75.75 0 00-.75.75V21a.75.75 0 01-.75.75H5.625a1.875 1.875 0 01-1.875-1.875v-6.198a2.29 2.29 0 00.091-.086L12 5.43z" />
                                </svg>
                                Demak, ID
                            </p>
                        </div>
                    </div>
                </div>

                {/* <!--Copyright section--> */}
                <div className="7 p-6 text-center dark:7">
                    <span>© 2024 Copyright : </span>
                    <a
                        className="font-semibold text-white"
                        href="https://joki.Arsipan.gapenting_.my.id/"
                    >rraanggaaaa</a>
                </div>
            </div>
    );
};
export default Footer;

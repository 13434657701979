import React, { useEffect, useState } from 'react';
import WeeklyRevenue from './WeeklyRevenue';
import { MdDashboard } from "react-icons/md";
import TotalSpent from './TotalSpent';
import Footer from './Footer';
import Navbar from './Navbar';
import Widget from './Widget';
import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import { getYear } from 'date-fns';

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBr4y3JRw8Wq35A5nfxBPxyOAOtRneSNPg",
  authDomain: "tabunganbersama-58f2e.firebaseapp.com",
  projectId: "tabunganbersama-58f2e",
  storageBucket: "tabunganbersama-58f2e.appspot.com",
  messagingSenderId: "335269822572",
  appId: "1:335269822572:web:18bc0a52a32f3d3cf85849",
  measurementId: "G-VH2GS1PLQ6"
};

const app = initializeApp(firebaseConfig);

const Welcome = () => {
  const [members, setMembers] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1); // Default: current month

  useEffect(() => {
    const fetchData = async () => {
      const db = getFirestore(app);

      // Fetch members
      const membersSnapshot = await getDocs(collection(db, "members"));
      const membersList = membersSnapshot.docs.map(doc => doc.data().name);
      setMembers(membersList);

      // Fetch transactions
      const transactionsSnapshot = await getDocs(collection(db, "tabunganbersama"));
      const transactionsList = transactionsSnapshot.docs.map(doc => ({
        ...doc.data(),
        id: doc.id,
      }));
      setTransactions(transactionsList);
    };

    fetchData();
  }, []);

  const getCurrentYear = () => getYear(new Date());

  const membersStatus = members.map(member => {
    const hasPaidThisWeek = transactions.some(transaction => {
      const transactionDate = transaction.timestamp ? transaction.timestamp.toDate() : null;
      const transactionYear = transactionDate ? getYear(transactionDate) : null;

      return (
        transaction.name === member &&
        transactionYear === getCurrentYear()
      );
    });

    return {
      name: member,
      subtitle: hasPaidThisWeek ? "Sudah Bayar" : "Belum Bayar"
    };
  });

  const generateWeeks = (month) => {
    const weeks = [];
    const startDate = new Date(new Date().getFullYear(), month - 1, 1);
    const endDate = new Date(new Date().getFullYear(), month, 0);
    let currentDate = startDate;
    let weekNumber = 1;

    while (currentDate <= endDate) {
      weeks.push(weekNumber);
      weekNumber++;
      currentDate.setDate(currentDate.getDate() + 7);
    }

    return weeks;
  };

  const weeksInMonth = generateWeeks(selectedMonth);

  return (
    <div className="min-h-screen bg-emerald-700">
      <header>
        <Navbar />
      </header>

      <div className="h-full">
        <div className="relative isolate flex justify-center items-center overflow-hidden bg-slate-900 py-24 sm:py-32 rounded-b-3xl">
          <img
            src="https://i.ibb.co.com/bH3GSHq/Desain-tanpa-judul-4.png"
            alt=""
            className="absolute inset-0 -z-10 h-full w-full object-cover object-right md:object-center"
          />
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="flex justify-center items-center mx-auto max-w-2xl lg:mx-0">
              <h1 className='font-loader text-4xl font-mono font-bold tracking-tight text-white sm:text-3xl'>Tabungan Bersama</h1>
            </div>
            <div className="flex mx-auto text-center justify-center items-center max-w-2xl lg:mx-0">
              <p className="mt-4 text-lg leading-8 text-emerald-400">
                Tabungan Masa Depan <br></br> @ssilmaaaaa123 @partofmwla @txtdarihelmi @apregita_ @umilailiyr @keynlv @rraanggaaaa  <br></br> YANG BENERAN GA PENTINGGGgGGgGGg
              </p>
            </div>
          </div>
        </div>

        <div id='chart' className='min-h-screen -mt-4 text-black grid justify-center items-center bg-slate-300'>
          <div className="grid gap-8 grid-cols-1 my-8">
            <div className="flex flex-col md:flex-row bg-slate-200 h-auto w-auto mx-6 p-6 rounded-lg items-center justify-center">
              <div className='mx-10 w-full flex-col justify-center items-center'>
                <WeeklyRevenue />
              </div>
              <div className='mx-10 w-full flex-col justify-center items-center mt-4 md:mt-0'>
                <TotalSpent />
              </div>
            </div>
          </div>

          <div className='flex justify-center'>
            <div className="flex text-center justify-center items-center max-w-2xl">
              <p className="mt-4 text-lg font-bold text-black">
                Report Pembayaran Mingguan:
              </p>
            </div>
          </div>
          <div className="p-10 grid gap-5 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
            {membersStatus.map((status) => (
              <Widget
                key={status.name}
                title={status.name}
                subtitle={status.subtitle}
                icon={<MdDashboard className="h-6 w-6" />}
                className={status.subtitle === "Sudah Bayar" ? "text-green-500" : "text-red-500"}
              />
            ))}
          </div>

          <div className='flex justify-center'>
            <div className="flex text-center justify-center items-center max-w-2xl">
              <p className="mt-4 text-lg font-bold text-black">
                Report Semua:
              </p>
            </div>
          </div>
          <div className="flex justify-center mt-4">
            <label className="mr-2">Pilih Bulan:</label>
            <select
              className="px-3 py-2 border rounded bg-white"
              value={selectedMonth}
              onChange={(e) => setSelectedMonth(parseInt(e.target.value))}
            >
              {Array.from({ length: 12 }, (_, index) => (
                <option key={index} value={index + 1}>
                  {new Date(0, index).toLocaleString('id', { month: 'long' })}
                </option>
              ))}
            </select>
          </div>

          <div className="mt-6 mb-10 bg-white p-6 rounded-lg shadow-lg w-full max-w-4xl mx-auto overflow-x-auto">
            <h2 className="text-xl font-bold mb-4">Daftar Pembayaran Perminggu</h2>
            <table className="min-w-full bg-white border">
              <thead>
                <tr>
                  <th className="px-4 py-2 border">Nama Anggota</th>
                  {weeksInMonth.map((week, index) => (
                    <th key={index} className="px-4 py-2 border">Minggu {week}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {members.map(member => (
                  <tr key={member}>
                    <td className="px-4 py-2 border">{member}</td>
                    {weeksInMonth.map((week, index) => {
                      const hasPaidForWeek = transactions.some(transaction => {
                        const transactionDate = transaction.timestamp ? transaction.timestamp.toDate() : null;
                        return (
                          transaction.name === member &&
                          Math.ceil(transactionDate.getDate() / 7) === week &&
                          transactionDate.getMonth() + 1 === selectedMonth
                        );
                      });
                      return (
                        <td key={index}  className={`px-4 py-2 border text-center ${hasPaidForWeek ? 'bg-green-500' : 'bg-red-400'}`}>
                          {hasPaidForWeek ? "✔" : "❌"}
                        </td>
                      );
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default Welcome;
